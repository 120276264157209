<template>
  <div class="drivers-step1-content">
    <!-- 按钮栏 -->
    <div class="bg-white flex flex-row">
      <div class="pl-24 pr-6 flex-1">
        <div class="d-flex justify-space-between">
          <label class=" ml-10 text-14 text-main font-500">行驶证主副页<span class="text-danger">(挂车)</span></label>
        </div>
        <div class="truck-card-wrap py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseMainFileList"
            @afterUploaded="afterUploadedTruckCard"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
      <div class="pl-6 pr-24 flex-1">
        <div class="d-flex justify-space-between">
          <label class="ml-5 text-14 text-main font-500">行驶证其他页<span class="text-danger">(挂车)</span></label>
        </div>
        <div class="truck-card-wrap  py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseSideFileList"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
    </div>
    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          readonly
          v-if="!item.hidden && key == 'trailerPlateNumber'"
          @click="FormObj[key].visible = true"
          :required="true"
          :value="truckRef[key]"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          :type="item.type"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
        </van-field>
        <van-field
          readonly
          v-if="!item.hidden && key == 'trailerType'"
          @click="trailerTypeShowRef = true"
          :required="item.rules[0].required"
          :value="truckRef[key]"
          input-align="right"
          :name="item.name"
          placeholder="请选择"
          rows="1"
          :key="key"
          :rules="item.rules"
          is-link
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes('trailerTypeCode') }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes('trailerTypeCode')">
                不正确
              </div>
            </div>
          </template>
        </van-field>
        <van-field
          v-if="key === 'trailerLoadWeight' && !item.hidden"
          :required="item.rules[0].required"
          v-model="truckRef[key]"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          type="digit"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template v-if="item.extra" #extra>
            <div class="ml-8">{{ item.extra }}</div>
          </template>
        </van-field>
        <van-field
          v-if="key === 'loadLength' && !item.hidden"
          :required="item.rules[0].required"
          v-model="truckRef[key]"
          @blur="onLoadLengthChange"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          type="number"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template #extra>
            <div class="ml-8 text-primary" @click="lengthPopShow = true">推荐</div>
          </template>
        </van-field>
      </template>
    </van-form>

    <div>
      <div class="truck-btn-box">
        <Button size="large" :disabled="next" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>

    <!-- 挂车牌照 -->
    <Popup v-model="FormObj.trailerPlateNumber.visible" position="bottom" round>
      <KeyBoard
        :defaultValue="truckRef.trailerPlateNumber || ''"
        @getPlateLicense="getTrailerNo"
        defaultType="trailer"
        @hide="FormObj.trailerPlateNumber.visible = false"
      />
    </Popup>

    <Popup v-model="trailerTypeShowRef" :style="{ height: '100%' }" position="left">
      <TruckTypePicker
        title="请选择车辆类型"
        v-model="truckRef.trailerType"
        :columns="trailerTypesOptions"
        @confirm="onTrailerTypeChange"
        @cancel="trailerTypeShowRef = false"
      />
    </Popup>
    <van-popup v-model="lengthPopShow" position="bottom">
      <van-picker
        title="推荐长度"
        show-toolbar
        :columns="lengthOptions"
        value-key="text"
        @confirm="onLengthConfirm"
        @cancel="lengthPopShow = false"
      />
    </van-popup>
  </div>
</template>
<script>
import TosUploader from '@/components/uploader/uploader.vue';
import { defineComponent, ref, computed, reactive, onMounted } from '@vue/composition-api';
import KeyBoard from '../components/carBoard';
import { Button, Popup, Picker } from 'vant';

import { useTruckVerifiedStore } from '@/store/truckVerified.js';
import { apiOcrVehicleLicense } from '@/api/ocr';

import { getStepTrailerFormData } from '../constants';

import TruckTypePicker from './TruckTypePicker';

import { formatWeightOcr } from '@/utils/ocr';
import BigNumber from 'bignumber.js';

export default defineComponent({
  name: 'carAddStepTrailer',
  components: {
    TosUploader,
    Button,
    Popup,
    KeyBoard,

    TruckTypePicker,
    'van-picker': Picker
  },
  setup(_, ctx) {
    const userVerifiedStore = useTruckVerifiedStore();

    // 认证模式

    const popVisible = ref(false);
    const disabled = ref(false); // 全局禁用
    const next = ref(false);

    const truckRef = computed(() => userVerifiedStore.verifiedInfo);
    const truckLicenseMainFileList = ref([]);
    const truckLicenseSideFileList = ref([]);

    const FormData = getStepTrailerFormData(truckRef);
    const FormObj = reactive(FormData);

    onMounted(() => {
      if (userVerifiedStore?.verifiedInfo?.trailerDrivingLicenseMainUrl) {
        truckLicenseMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.trailerDrivingLicenseMainUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.trailerDrivingLicenseSideUrl) {
        truckLicenseSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.trailerDrivingLicenseSideUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
    });

    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      let flag = true;
      if (form) {
        try {
          flag = await form.validate(...params);
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      return flag;
    };

    const afterUploadedTruckCard = async file => {
      // userVerifiedStore.updateImagePass(false);
      if (userVerifiedStore.verifiedInfo.trailerPlateNumber && userVerifiedStore.verifiedInfo.trailerLoadWeight) return;
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      next.value = true;
      try {
        const res = await apiOcrVehicleLicense({ imageUrl: file.url });
        const { data } = res;
        const { carNumber, vehicleType } = data;
        const { loadWeight } = formatWeightOcr(data);
        let updateObj = {};
        const validateKeys = [];
        if (carNumber) {
          updateObj.trailerPlateNumber = carNumber;
          validateKeys.push('trailerPlateNumber');
        }
        if (loadWeight) {
          updateObj.trailerLoadWeight = loadWeight;
          validateKeys.push('trailerLoadWeight');
        }
        if (vehicleType) {
          const typeObj = getCarTypeCode(vehicleType);
          if (typeObj) {
            updateObj = { ...updateObj, ...typeObj };
          }
        }
        userVerifiedStore.updateInfo(updateObj);
        validateKeys();
      } catch (error) {
        // userVerifiedStore.updateImagePass(true);
        disabled.value = false;
        file.status = 'done';
      } finally {
        next.value = false;
      }
    };

    const getCarTypeCode = name => {
      const tree = trailerTypesOptions.value || [];
      const flattedTypeList = tree.reduce((res, cur) => {
        let children = cur.secondCarTypes || [];
        if (children.length) {
          res = [...res, ...children];
        }
        return res;
      }, []);
      const data = flattedTypeList.find(it => it.name === name);
      if (data) {
        return {
          trailerTypeCode: data.code,
          trailerType: data.name
        };
      }
      return null;
    };

    const handleToNext = async () => {
      const isValid = await validateForm();
      if (!isValid) return;
      userVerifiedStore.updateInfo({
        trailerDrivingLicenseMainUrl: truckLicenseMainFileList?.value[0]?.fileName || '',
        trailerDrivingLicenseSideUrl: truckLicenseSideFileList?.value[0]?.fileName || ''
      });
      userVerifiedStore.getAxlesByLength(userVerifiedStore.verifiedInfo.loadLength);
      userVerifiedStore.setCurrentStep(2);
    };

    const handleInput = (value, key) => {
      truckRef.value[key] = value;
    };

    const onTrailerTypeChange = e => {
      userVerifiedStore.updateInfo({
        trailerType: e.value,
        trailerTypeCode: e.key
      });
      trailerTypeShowRef.value = false;
      validateForm('trailerType');
    };
    const getTrailerNo = e => {
      userVerifiedStore.updateInfo({
        trailerPlateNumber: e
      });
    };

    const getLabel = key => {
      return FormObj[key].label;
    };

    const trailerTypeShowRef = ref(false);
    const lengthPopShow = ref(false);
    const trailerTypesOptions = computed(() => {
      return userVerifiedStore.trailerTypeOptions || [];
    });

    const lengthOptions = computed(() => {
      return userVerifiedStore.lengthOptions || [];
    });
    const onLengthConfirm = ({ text }) => {
      userVerifiedStore.updateInfo({
        loadLength: text
      });
      lengthPopShow.value = false;
      validateForm('loadLength');
    };

    const lengthTransfer = val => {
      if ((!val && val !== 0) || isNaN(val)) return null;
      const number = +new BigNumber(val).toFixed(1, BigNumber.ROUND_DOWN);
      return '' + number;
    };
    const onLoadLengthChange = () => {
      const { loadLength } = userVerifiedStore.verifiedInfo;
      const val = lengthTransfer(loadLength);
      userVerifiedStore.updateInfo({
        loadLength: val
      });
    };

    return {
      next,
      truckRef,
      popVisible,

      handleToNext,
      truckLicenseMainFileList,
      truckLicenseSideFileList,
      afterUploadedTruckCard,

      FormObj,
      handleInput,

      getTrailerNo,
      getLabel,
      trailerTypeShowRef,
      lengthPopShow,
      trailerTypesOptions,
      lengthOptions,
      onLengthConfirm,
      onLoadLengthChange,
      onTrailerTypeChange
    };
  }
});
</script>
<style lang="less">
.truck-card-wrap {
  // padding: 0.04rem 0.08rem;
  .truck-card {
    width: 100%;
    height: 1rem;
  }
}
.form-title {
  position: relative;
  display: inline-block;
  width: auto;
  .important::after {
    position: absolute;
    right: -10px;
    color: #ee0a24;
    font-size: 24px;
    top: -2px;
    text-align: center;
    vertical-align: middle;
    content: '*';
  }
}
</style>
